/* Partly copied from https://github.com/leanprover/vscode-lean4/blob/master/lean4-infoview/src/infoview/main.tsx */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import * as React from 'react';
import 'tachyons/css/tachyons.css';
// import '@vscode/codicons/dist/codicon.ttf';
import '@vscode/codicons/dist/codicon.css';
import '../../../../node_modules/lean4-infoview/src/infoview/index.css';
import './infoview.css';
import { defaultInfoviewConfig } from '@leanprover/infoview-api';
import { Infos } from './infos';
import { WithLspDiagnosticsContext } from './messages';
import { useClientNotificationEffect, useServerNotificationEffect, useEventResult, useServerNotificationState } from '../../../../node_modules/lean4-infoview/src/infoview/util';
import { EditorContext, ConfigContext, ProgressContext, VersionContext } from '../../../../node_modules/lean4-infoview/src/infoview/contexts';
import { WithRpcSessions } from '../../../../node_modules/lean4-infoview/src/infoview/rpcSessions';
import { ServerVersion } from '../../../../node_modules/lean4-infoview/src/infoview/serverVersion';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { levelCompleted, selectCompleted } from '../../state/progress';
import { GameIdContext } from '../../App';
export function Main(props) {
    var _this = this;
    var _a;
    var ec = React.useContext(EditorContext);
    var gameId = React.useContext(GameIdContext);
    var dispatch = useAppDispatch();
    // Mark level as completed when server gives notification
    useServerNotificationEffect('$/game/completed', function (params) {
        if (ec.events.changedCursorLocation.current &&
            ec.events.changedCursorLocation.current.uri === params.uri) {
            dispatch(levelCompleted({ game: gameId, world: props.world, level: props.level }));
        }
    }, []);
    var completed = useAppSelector(selectCompleted(gameId, props.world, props.level));
    /* Set up updates to the global infoview state on editor events. */
    var config = (_a = useEventResult(ec.events.changedInfoviewConfig)) !== null && _a !== void 0 ? _a : defaultInfoviewConfig;
    var _b = __read(useServerNotificationState('$/lean/fileProgress', new Map(), function (params) { return __awaiter(_this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, function (allProgress) {
                    var newProgress = new Map(allProgress);
                    return newProgress.set(params.textDocument.uri, params.processing);
                }];
        });
    }); }, []), 2), allProgress = _b[0], _1 = _b[1];
    var curUri = useEventResult(ec.events.changedCursorLocation, function (loc) { return loc === null || loc === void 0 ? void 0 : loc.uri; });
    useClientNotificationEffect('textDocument/didClose', function (params) {
        if (ec.events.changedCursorLocation.current &&
            ec.events.changedCursorLocation.current.uri === params.textDocument.uri) {
            ec.events.changedCursorLocation.fire(undefined);
        }
    }, []);
    var serverVersion = useEventResult(ec.events.serverRestarted, function (result) { var _a, _b; return new ServerVersion((_b = (_a = result.serverInfo) === null || _a === void 0 ? void 0 : _a.version) !== null && _b !== void 0 ? _b : ''); });
    var serverStoppedResult = useEventResult(ec.events.serverStopped);
    // NB: the cursor may temporarily become `undefined` when a file is closed. In this case
    // it's important not to reconstruct the `WithBlah` wrappers below since they contain state
    // that we want to persist.
    var ret;
    if (!serverVersion) {
        ret = React.createElement("p", null, "Waiting for Lean server to start...");
    }
    else if (serverStoppedResult) {
        ret = React.createElement("div", null,
            React.createElement("p", null, serverStoppedResult.message),
            React.createElement("p", { className: "error" }, serverStoppedResult.reason));
    }
    else {
        ret = React.createElement("div", { className: "infoview vscode-light" },
            completed && React.createElement("div", { className: "level-completed" }, "Level completed! \uD83C\uDF89"),
            React.createElement(Infos, null));
    }
    return (React.createElement(ConfigContext.Provider, { value: config },
        React.createElement(VersionContext.Provider, { value: serverVersion },
            React.createElement(WithRpcSessions, null,
                React.createElement(WithLspDiagnosticsContext, null,
                    React.createElement(ProgressContext.Provider, { value: allProgress }, ret))))));
}
