var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
import * as React from 'react';
import { useEffect } from 'react';
import './welcome.css';
import cytoscape from 'cytoscape';
import klay from 'cytoscape-klay';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Split from 'react-split';
import GameMenu from './GameMenu';
cytoscape.use(klay);
import { Box, Typography, CircularProgress } from '@mui/material';
import { useGetGameInfoQuery } from '../state/api';
import { Link } from 'react-router-dom';
import Markdown from './Markdown';
import { selectCompleted } from '../state/progress';
import { GameIdContext } from '../App';
var N = 24; // max number of levels per world
var R = 800; // radius of a world
var r = 110; // radius of a level
var s = 100; // global scale
var padding = 2000; // padding of the graphic (on a different scale)
function LevelIcon(_a) {
    var worldId = _a.worldId, levelId = _a.levelId, position = _a.position;
    var gameId = React.useContext(GameIdContext);
    var completed = useSelector(selectCompleted(gameId, worldId, levelId));
    var x = s * position.x + Math.sin(levelId * 2 * Math.PI / N) * (R + 1.2 * r + 2 * Math.floor((levelId - 1) / N));
    var y = s * position.y - Math.cos(levelId * 2 * Math.PI / N) * (R + 1.2 * r + 2 * Math.floor((levelId - 1) / N));
    // TODO: relative positioning?
    return (React.createElement(Link, { to: "/".concat(gameId, "/world/").concat(worldId, "/level/").concat(levelId) },
        React.createElement("circle", { fill: completed ? "green" : "#999", cx: x, cy: y, r: r })));
}
function Welcome() {
    var _a, _b, _c;
    var navigate = useNavigate();
    var gameId = React.useContext(GameIdContext);
    var gameInfo = useGetGameInfoQuery({ game: gameId });
    var _d = gameInfo.data ? computeWorldLayout((_a = gameInfo.data) === null || _a === void 0 ? void 0 : _a.worlds) : { nodes: [] }, nodes = _d.nodes, bounds = _d.bounds;
    useEffect(function () {
        var _a;
        if ((_a = gameInfo.data) === null || _a === void 0 ? void 0 : _a.title) {
            window.document.title = gameInfo.data.title;
        }
    }, [(_b = gameInfo.data) === null || _b === void 0 ? void 0 : _b.title]);
    var svgElements = [];
    if (gameInfo.data) {
        for (var i in gameInfo.data.worlds.edges) {
            var edge = gameInfo.data.worlds.edges[i];
            svgElements.push(React.createElement("line", { key: "pathway".concat(i), x1: s * nodes[edge[0]].position.x, y1: s * nodes[edge[0]].position.y, x2: s * nodes[edge[1]].position.x, y2: s * nodes[edge[1]].position.y, stroke: "#1976d2", strokeWidth: s }));
        }
        for (var id in nodes) {
            var position = nodes[id].position;
            for (var i = 1; i <= gameInfo.data.worldSize[id]; i++) {
                svgElements.push(React.createElement(LevelIcon, { key: "/".concat(gameId, "/world/").concat(id, "/level/").concat(i), position: position, worldId: id, levelId: i }));
            }
            svgElements.push(React.createElement(Link, { key: "world".concat(id), to: "/".concat(gameId, "/world/").concat(id, "/level/0") },
                React.createElement("circle", { className: "world-circle", cx: s * position.x, cy: s * position.y, r: R, fill: "#1976d2" }),
                React.createElement("foreignObject", { className: "world-title-wrapper", x: s * position.x, y: s * position.y, width: 1.42 * R, height: 1.42 * R, transform: "translate(" + -.71 * R + "," + -.71 * R + ")" },
                    React.createElement("div", null,
                        React.createElement("p", { className: "world-title", style: { fontSize: Math.floor(R / 4) + "px" } }, nodes[id].data.title ? nodes[id].data.title : id)))));
        }
    }
    return React.createElement("div", { className: "app-content " }, gameInfo.isLoading ?
        React.createElement(Box, { display: "flex", alignItems: "center", justifyContent: "center", sx: { height: "calc(100vh - 64px)" } },
            React.createElement(CircularProgress, null))
        :
            React.createElement(Split, { className: "welcome", minSize: 200, sizes: [70, 30] },
                React.createElement("div", { className: "column" },
                    React.createElement(Typography, { variant: "body1", component: "div", className: "welcome-text" },
                        React.createElement(Markdown, null, (_c = gameInfo.data) === null || _c === void 0 ? void 0 : _c.introduction))),
                React.createElement("div", { className: "column" },
                    React.createElement(GameMenu, null),
                    React.createElement(Box, { textAlign: 'center', sx: { m: 5 } },
                        React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", xmlnsXlink: "http://www.w3.org/1999/xlink", viewBox: bounds ? "".concat(s * bounds.x1 - padding, " ").concat(s * bounds.y1 - padding, " ").concat(s * bounds.x2 - s * bounds.x1 + 2 * padding, " ").concat(s * bounds.y2 - s * bounds.y1 + 2 * padding) : '' }, svgElements)))));
}
export default Welcome;
function computeWorldLayout(worlds) {
    var e_1, _a;
    var elements = [];
    for (var id in worlds.nodes) {
        elements.push({ data: { id: id, title: worlds.nodes[id].title } });
    }
    try {
        for (var _b = __values(worlds.edges), _c = _b.next(); !_c.done; _c = _b.next()) {
            var edge = _c.value;
            elements.push({
                data: {
                    id: edge[0] + " --edge-to--> " + edge[1],
                    source: edge[0],
                    target: edge[1]
                }
            });
        }
    }
    catch (e_1_1) { e_1 = { error: e_1_1 }; }
    finally {
        try {
            if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
        }
        finally { if (e_1) throw e_1.error; }
    }
    var cy = cytoscape({
        container: null,
        elements: elements,
        headless: true,
        styleEnabled: false
    });
    var layout = cy.layout({ name: "klay", klay: { direction: "DOWN", nodePlacement: "LINEAR_SEGMENTS" } }).run();
    var nodes = {};
    cy.nodes().forEach(function (node, id) {
        nodes[node.id()] = {
            position: node.position(),
            data: node.data()
        };
    });
    var bounds = cy.nodes().boundingBox();
    return { nodes: nodes, bounds: bounds };
}
