var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from 'react';
import { Link } from "react-router-dom";
export function Button(props) {
    if (props.disabled) {
        return React.createElement("span", __assign({ className: "btn btn-disabled ".concat(props.inverted ? 'btn-inverted' : '') }, props), props.children);
    }
    else {
        return React.createElement(Link, __assign({ className: "btn ".concat(props.inverted ? 'btn-inverted' : '') }, props), props.children);
    }
}
