import * as React from 'react';
import { Outlet, useParams } from "react-router-dom";
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import './reset.css';
import './app.css';
export var GameIdContext = React.createContext(undefined);
function App() {
    var params = useParams();
    return (React.createElement("div", { className: "app" },
        React.createElement(GameIdContext.Provider, { value: "g/" + params.owner + "/" + params.repo },
            React.createElement(Outlet, null))));
}
export default App;
