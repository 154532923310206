var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
import * as React from 'react';
import { useEffect, useState, useRef } from 'react';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { useParams } from 'react-router-dom';
import { CircularProgress, FormControlLabel, FormGroup, Switch } from '@mui/material';
import { Inventory, Documentation } from './Inventory';
import { LeanTaskGutter } from 'lean4web/client/src/editor/taskgutter';
import { AbbreviationProvider } from 'lean4web/client/src/editor/abbreviation/AbbreviationProvider';
import 'lean4web/client/src/editor/vscode.css';
import 'lean4web/client/src/editor/infoview.css';
import { AbbreviationRewriter } from 'lean4web/client/src/editor/abbreviation/rewriter/AbbreviationRewriter';
import { InfoProvider } from 'lean4web/client/src/editor/infoview';
import 'lean4web/client/src/editor/infoview.css';
import * as monaco from 'monaco-editor/esm/vs/editor/editor.api.js';
import './level.css';
import { Button } from './Button';
import { ConnectionContext, useLeanClient } from '../connection';
import { useGetGameInfoQuery, useLoadLevelQuery } from '../state/api';
import { changedSelection, codeEdited, selectCode, selectSelections, selectCompleted } from '../state/progress';
import { useAppDispatch, useAppSelector } from '../hooks';
import { useStore } from 'react-redux';
import { EditorContext } from '../../../node_modules/lean4-infoview/src/infoview/contexts';
import { EditorConnection } from '../../../node_modules/lean4-infoview/src/infoview/editorConnection';
import { EventEmitter } from '../../../node_modules/lean4-infoview/src/infoview/event';
import { Main } from './infoview/main';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faArrowRight, faArrowLeft, faRotateLeft } from '@fortawesome/free-solid-svg-icons';
import { useTheme } from '@mui/material/styles';
import Markdown from './Markdown';
import Split from 'react-split';
import { GameIdContext } from '../App';
export var MonacoEditorContext = React.createContext(null);
export var InputModeContext = React.createContext({
    commandLineMode: true,
    setCommandLineMode: function () { },
    commandLineInput: "",
    setCommandLineInput: function () { },
});
function Level() {
    var params = useParams();
    var levelId = parseInt(params.levelId);
    var worldId = params.worldId;
    useLoadWorldFiles(worldId);
    if (levelId == 0) {
        return React.createElement(Introduction, { worldId: worldId });
    }
    else {
        return React.createElement(PlayableLevel, { worldId: worldId, levelId: levelId });
    }
}
function PlayableLevel(_a) {
    var _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p;
    var worldId = _a.worldId, levelId = _a.levelId;
    var codeviewRef = useRef(null);
    var introductionPanelRef = useRef(null);
    var gameId = React.useContext(GameIdContext);
    var initialCode = useAppSelector(selectCode(gameId, worldId, levelId));
    var initialSelections = useAppSelector(selectSelections(gameId, worldId, levelId));
    var _q = __read(useState(true), 2), commandLineMode = _q[0], setCommandLineMode = _q[1];
    var _r = __read(useState(""), 2), commandLineInput = _r[0], setCommandLineInput = _r[1];
    var _s = __read(useState(initialCode.trim() !== ""), 2), canUndo = _s[0], setCanUndo = _s[1];
    var theme = useTheme();
    useEffect(function () {
        // Scroll to top when loading a new level
        introductionPanelRef.current.scrollTo(0, 0);
        // Reset command line input when loading a new level
        setCommandLineInput("");
    }, [levelId]);
    React.useEffect(function () {
        if (!commandLineMode) {
            // Delete last input attempt from command line
            editor.executeEdits("command-line", [{
                    range: editor.getSelection(),
                    text: "",
                    forceMoveMarkers: false
                }]);
            editor.focus();
        }
    }, [commandLineMode]);
    var handleUndo = function () {
        var endPos = editor.getModel().getFullModelRange().getEndPosition();
        var range;
        console.log(endPos.column);
        if (endPos.column === 1) {
            range = monaco.Selection.fromPositions(new monaco.Position(endPos.lineNumber - 1, 1), endPos);
        }
        else {
            range = monaco.Selection.fromPositions(new monaco.Position(endPos.lineNumber, 1), endPos);
        }
        editor.executeEdits("undo-button", [{
                range: range,
                text: "",
                forceMoveMarkers: false
            }]);
    };
    var gameInfo = useGetGameInfoQuery({ game: gameId });
    var level = useLoadLevelQuery({ game: gameId, world: worldId, level: levelId });
    var dispatch = useAppDispatch();
    var onDidChangeContent = function (code) {
        dispatch(codeEdited({ game: gameId, world: worldId, level: levelId, code: code }));
        setCanUndo(code.trim() !== "");
    };
    var onDidChangeSelection = function (monacoSelections) {
        var selections = monacoSelections.map(function (_a) {
            var selectionStartLineNumber = _a.selectionStartLineNumber, selectionStartColumn = _a.selectionStartColumn, positionLineNumber = _a.positionLineNumber, positionColumn = _a.positionColumn;
            return { selectionStartLineNumber: selectionStartLineNumber, selectionStartColumn: selectionStartColumn, positionLineNumber: positionLineNumber, positionColumn: positionColumn };
        });
        dispatch(changedSelection({ game: gameId, world: worldId, level: levelId, selections: selections }));
    };
    var completed = useAppSelector(selectCompleted(gameId, worldId, levelId));
    var _t = useLevelEditor(worldId, levelId, codeviewRef, initialCode, initialSelections, onDidChangeContent, onDidChangeSelection), editor = _t.editor, infoProvider = _t.infoProvider, editorConnection = _t.editorConnection;
    // Effect when command line mode gets enabled
    useEffect(function () {
        if (editor && commandLineMode) {
            var endPos = editor.getModel().getFullModelRange().getEndPosition();
            if (editor.getModel().getLineContent(endPos.lineNumber).trim() !== "") {
                editor.executeEdits("command-line", [{
                        range: monaco.Selection.fromPositions(endPos, endPos),
                        text: "\n",
                        forceMoveMarkers: true
                    }]);
            }
            endPos = editor.getModel().getFullModelRange().getEndPosition();
            var currPos = editor.getPosition();
            if (currPos.column != 1 || (currPos.lineNumber != endPos.lineNumber && currPos.lineNumber != endPos.lineNumber - 1)) {
                // This is not a position that would naturally occur from CommandLine, reset:
                editor.setSelection(monaco.Selection.fromPositions(endPos, endPos));
            }
        }
    }, [editor, commandLineMode]);
    // if this is set to a pair `(name, type)` then the according doc will be open.
    var _u = __read(useState(null), 2), inventoryDoc = _u[0], setInventoryDoc = _u[1];
    var levelTitle = React.createElement(React.Fragment, null,
        levelId && "Level ".concat(levelId),
        ((_b = level === null || level === void 0 ? void 0 : level.data) === null || _b === void 0 ? void 0 : _b.title) && ": ".concat((_c = level === null || level === void 0 ? void 0 : level.data) === null || _c === void 0 ? void 0 : _c.title));
    return React.createElement(React.Fragment, null,
        React.createElement("div", { style: level.isLoading ? null : { display: "none" }, className: "app-content loading" },
            React.createElement(CircularProgress, null)),
        React.createElement(LevelAppBar, { isLoading: level.isLoading, levelTitle: levelTitle, worldId: worldId, levelId: levelId }),
        React.createElement(Split, { minSize: 0, snapOffset: 200, sizes: [50, 25, 25], className: "app-content level ".concat(level.isLoading ? 'hidden' : '') },
            React.createElement("div", { className: "exercise-panel" },
                React.createElement("div", { ref: introductionPanelRef, className: "introduction-panel" }, ((_d = level === null || level === void 0 ? void 0 : level.data) === null || _d === void 0 ? void 0 : _d.introduction) &&
                    React.createElement("div", { className: "message info" },
                        React.createElement(Markdown, null, (_e = level === null || level === void 0 ? void 0 : level.data) === null || _e === void 0 ? void 0 : _e.introduction))),
                React.createElement("div", { className: "exercise" },
                    React.createElement(Markdown, null, (((_f = level === null || level === void 0 ? void 0 : level.data) === null || _f === void 0 ? void 0 : _f.statementName) ?
                        "**Theorem** `".concat((_g = level === null || level === void 0 ? void 0 : level.data) === null || _g === void 0 ? void 0 : _g.statementName, "`: ")
                        :
                            ((_h = level === null || level === void 0 ? void 0 : level.data) === null || _h === void 0 ? void 0 : _h.descrText) && "**Exercise**: ")
                        + "".concat((_j = level === null || level === void 0 ? void 0 : level.data) === null || _j === void 0 ? void 0 : _j.descrText)),
                    React.createElement("div", { className: "statement ".concat(commandLineMode ? 'hidden' : '') },
                        React.createElement("code", null, (_k = level === null || level === void 0 ? void 0 : level.data) === null || _k === void 0 ? void 0 : _k.descrFormat)),
                    React.createElement("div", { ref: codeviewRef, className: "codeview ".concat(commandLineMode ? 'hidden' : '') })),
                React.createElement("div", { className: "input-mode-switch" },
                    commandLineMode && React.createElement("button", { className: "btn", onClick: handleUndo, disabled: !canUndo },
                        React.createElement(FontAwesomeIcon, { icon: faRotateLeft }),
                        " Undo"),
                    React.createElement(FormGroup, null,
                        React.createElement(FormControlLabel, { control: React.createElement(Switch, { onChange: function (ev) { setCommandLineMode(!commandLineMode); } }), label: "Editor mode" }))),
                React.createElement(EditorContext.Provider, { value: editorConnection },
                    React.createElement(MonacoEditorContext.Provider, { value: editor },
                        React.createElement(InputModeContext.Provider, { value: { commandLineMode: commandLineMode, setCommandLineMode: setCommandLineMode, commandLineInput: commandLineInput, setCommandLineInput: setCommandLineInput } }, editorConnection && React.createElement(Main, { key: "".concat(worldId, "/").concat(levelId), world: worldId, level: levelId })))),
                completed && React.createElement("div", { className: "conclusion" },
                    ((_m = (_l = level === null || level === void 0 ? void 0 : level.data) === null || _l === void 0 ? void 0 : _l.conclusion) === null || _m === void 0 ? void 0 : _m.trim()) &&
                        React.createElement("div", { className: "message info" },
                            React.createElement(Markdown, null, (_o = level === null || level === void 0 ? void 0 : level.data) === null || _o === void 0 ? void 0 : _o.conclusion)),
                    levelId >= ((_p = gameInfo.data) === null || _p === void 0 ? void 0 : _p.worldSize[worldId]) ?
                        React.createElement(Button, { to: "/".concat(gameId) },
                            React.createElement(FontAwesomeIcon, { icon: faHome })) :
                        React.createElement(Button, { to: "/".concat(gameId, "/world/").concat(worldId, "/level/").concat(levelId + 1) },
                            "Next\u00A0",
                            React.createElement(FontAwesomeIcon, { icon: faArrowRight })))),
            React.createElement("div", { className: "inventory-panel" }, !level.isLoading &&
                React.createElement(Inventory, { levelInfo: level === null || level === void 0 ? void 0 : level.data, setInventoryDoc: setInventoryDoc })),
            React.createElement("div", { className: "doc-panel" }, inventoryDoc && React.createElement(Documentation, { name: inventoryDoc.name, type: inventoryDoc.type }))));
}
export default Level;
function Introduction(_a) {
    var _b, _c;
    var worldId = _a.worldId;
    var gameId = React.useContext(GameIdContext);
    var gameInfo = useGetGameInfoQuery({ game: gameId });
    return React.createElement(React.Fragment, null,
        React.createElement("div", { style: gameInfo.isLoading ? null : { display: "none" }, className: "app-content loading" },
            React.createElement(CircularProgress, null)),
        React.createElement(LevelAppBar, { isLoading: gameInfo.isLoading, levelTitle: "Einf\u00FChrung", worldId: worldId, levelId: 0 }),
        React.createElement("div", { style: gameInfo.isLoading ? { display: "none" } : null, className: "exercise-panel" },
            React.createElement("div", { className: "introduction-panel" },
                React.createElement("div", { className: "message info" },
                    React.createElement(Markdown, null, (_b = gameInfo.data) === null || _b === void 0 ? void 0 : _b.worlds.nodes[worldId].introduction))),
            React.createElement("div", { className: "conclusion" }, 0 == ((_c = gameInfo.data) === null || _c === void 0 ? void 0 : _c.worldSize[worldId]) ?
                React.createElement(Button, { to: "/".concat(gameId) },
                    React.createElement(FontAwesomeIcon, { icon: faHome })) :
                React.createElement(Button, { to: "/".concat(gameId, "/world/").concat(worldId, "/level/1") },
                    "Start\u00A0",
                    React.createElement(FontAwesomeIcon, { icon: faArrowRight })))));
}
function LevelAppBar(_a) {
    var _b, _c, _d;
    var isLoading = _a.isLoading, levelId = _a.levelId, worldId = _a.worldId, levelTitle = _a.levelTitle;
    var gameId = React.useContext(GameIdContext);
    var gameInfo = useGetGameInfoQuery({ game: gameId });
    return React.createElement("div", { className: "app-bar", style: isLoading ? { display: "none" } : null },
        React.createElement("div", null,
            React.createElement(Button, { to: "/".concat(gameId) },
                React.createElement(FontAwesomeIcon, { icon: faHome })),
            React.createElement("span", { className: "app-bar-title" }, ((_b = gameInfo.data) === null || _b === void 0 ? void 0 : _b.worlds.nodes[worldId].title) && "World: ".concat((_c = gameInfo.data) === null || _c === void 0 ? void 0 : _c.worlds.nodes[worldId].title))),
        React.createElement("div", null,
            React.createElement("span", { className: "app-bar-title" }, levelTitle),
            React.createElement(Button, { disabled: levelId <= 0, inverted: true, to: "/".concat(gameId, "/world/").concat(worldId, "/level/").concat(levelId - 1) },
                React.createElement(FontAwesomeIcon, { icon: faArrowLeft }),
                "\u00A0Previous"),
            React.createElement(Button, { disabled: levelId >= ((_d = gameInfo.data) === null || _d === void 0 ? void 0 : _d.worldSize[worldId]), inverted: true, to: "/".concat(gameId, "/world/").concat(worldId, "/level/").concat(levelId + 1) },
                "Next\u00A0",
                React.createElement(FontAwesomeIcon, { icon: faArrowRight }))));
}
function useLevelEditor(worldId, levelId, codeviewRef, initialCode, initialSelections, onDidChangeContent, onDidChangeSelection) {
    var _this = this;
    var connection = React.useContext(ConnectionContext);
    var gameId = React.useContext(GameIdContext);
    var _a = __read(useState(null), 2), editor = _a[0], setEditor = _a[1];
    var _b = __read(useState(null), 2), infoProvider = _b[0], setInfoProvider = _b[1];
    var _c = __read(useState(null), 2), infoviewApi = _c[0], setInfoviewApi = _c[1];
    var _d = __read(useState(null), 2), editorConnection = _d[0], setEditorConnection = _d[1];
    // Create Editor
    useEffect(function () {
        var editor = monaco.editor.create(codeviewRef.current, {
            glyphMargin: true,
            quickSuggestions: false,
            lightbulb: {
                enabled: true
            },
            unicodeHighlight: {
                ambiguousCharacters: false,
            },
            automaticLayout: true,
            minimap: {
                enabled: false
            },
            lineNumbersMinChars: 3,
            'semanticHighlighting.enabled': true,
            theme: 'vs-code-theme-converted'
        });
        var infoProvider = new InfoProvider(connection.getLeanClient(gameId));
        var editorApi = infoProvider.getApi();
        var editorEvents = {
            initialize: new EventEmitter(),
            gotServerNotification: new EventEmitter(),
            sentClientNotification: new EventEmitter(),
            serverRestarted: new EventEmitter(),
            serverStopped: new EventEmitter(),
            changedCursorLocation: new EventEmitter(),
            changedInfoviewConfig: new EventEmitter(),
            runTestScript: new EventEmitter(),
            requestedAction: new EventEmitter(),
        };
        // Challenge: write a type-correct fn from `Eventify<T>` to `T` without using `any`
        var infoviewApi = {
            initialize: function (l) { return __awaiter(_this, void 0, void 0, function () { return __generator(this, function (_a) {
                return [2 /*return*/, editorEvents.initialize.fire(l)];
            }); }); },
            gotServerNotification: function (method, params) { return __awaiter(_this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    editorEvents.gotServerNotification.fire([method, params]);
                    return [2 /*return*/];
                });
            }); },
            sentClientNotification: function (method, params) { return __awaiter(_this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    editorEvents.sentClientNotification.fire([method, params]);
                    return [2 /*return*/];
                });
            }); },
            serverRestarted: function (r) { return __awaiter(_this, void 0, void 0, function () { return __generator(this, function (_a) {
                return [2 /*return*/, editorEvents.serverRestarted.fire(r)];
            }); }); },
            serverStopped: function (serverStoppedReason) { return __awaiter(_this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    editorEvents.serverStopped.fire(serverStoppedReason);
                    return [2 /*return*/];
                });
            }); },
            changedCursorLocation: function (loc) { return __awaiter(_this, void 0, void 0, function () { return __generator(this, function (_a) {
                return [2 /*return*/, editorEvents.changedCursorLocation.fire(loc)];
            }); }); },
            changedInfoviewConfig: function (conf) { return __awaiter(_this, void 0, void 0, function () { return __generator(this, function (_a) {
                return [2 /*return*/, editorEvents.changedInfoviewConfig.fire(conf)];
            }); }); },
            requestedAction: function (action) { return __awaiter(_this, void 0, void 0, function () { return __generator(this, function (_a) {
                return [2 /*return*/, editorEvents.requestedAction.fire(action)];
            }); }); },
            // See https://rollupjs.org/guide/en/#avoiding-eval
            // eslint-disable-next-line @typescript-eslint/no-implied-eval
            runTestScript: function (script) { return __awaiter(_this, void 0, void 0, function () { return __generator(this, function (_a) {
                return [2 /*return*/, new Function(script)()];
            }); }); },
            getInfoviewHtml: function () { return __awaiter(_this, void 0, void 0, function () { return __generator(this, function (_a) {
                return [2 /*return*/, document.body.innerHTML];
            }); }); },
        };
        var ec = new EditorConnection(editorApi, editorEvents);
        setEditorConnection(ec);
        editorEvents.initialize.on(function (loc) { return ec.events.changedCursorLocation.fire(loc); });
        setEditor(editor);
        setInfoProvider(infoProvider);
        setInfoviewApi(infoviewApi);
        return function () { infoProvider.dispose(); editor.dispose(); };
    }, []);
    var _e = useLeanClient(gameId), leanClient = _e.leanClient, leanClientStarted = _e.leanClientStarted;
    // Create model when level changes
    useEffect(function () {
        if (editor && leanClientStarted) {
            var uri = monaco.Uri.parse("file:///".concat(worldId, "/").concat(levelId));
            var model_1 = monaco.editor.getModel(uri);
            if (!model_1) {
                model_1 = monaco.editor.createModel(initialCode, 'lean4', uri);
            }
            model_1.onDidChangeContent(function () { return onDidChangeContent(model_1.getValue()); });
            editor.onDidChangeCursorSelection(function () { return onDidChangeSelection(editor.getSelections()); });
            editor.setModel(model_1);
            if (initialSelections) {
                editor.setSelections(initialSelections);
            }
            infoviewApi.serverRestarted(leanClient.initializeResult);
            infoProvider.openPreview(editor, infoviewApi);
            var taskGutter_1 = new LeanTaskGutter(infoProvider.client, editor);
            var abbrevRewriter_1 = new AbbreviationRewriter(new AbbreviationProvider(), model_1, editor);
            return function () { abbrevRewriter_1.dispose(); taskGutter_1.dispose(); };
        }
    }, [editor, levelId, connection, leanClientStarted]);
    return { editor: editor, infoProvider: infoProvider, editorConnection: editorConnection };
}
/** Open all files in this world on the server so that they will load faster when accessed */
function useLoadWorldFiles(worldId) {
    var gameId = React.useContext(GameIdContext);
    var gameInfo = useGetGameInfoQuery({ game: gameId });
    var store = useStore();
    useEffect(function () {
        if (gameInfo.data) {
            var models_1 = [];
            for (var levelId = 1; levelId <= gameInfo.data.worldSize[worldId]; levelId++) {
                var uri = monaco.Uri.parse("file:///".concat(worldId, "/").concat(levelId));
                var model = monaco.editor.getModel(uri);
                if (model) {
                    models_1.push(model);
                }
                else {
                    var code = selectCode(gameId, worldId, levelId)(store.getState());
                    models_1.push(monaco.editor.createModel(code, 'lean4', uri));
                }
            }
            return function () {
                var e_1, _a;
                try {
                    for (var models_2 = __values(models_1), models_2_1 = models_2.next(); !models_2_1.done; models_2_1 = models_2.next()) {
                        var model = models_2_1.value;
                        model.dispose();
                    }
                }
                catch (e_1_1) { e_1 = { error: e_1_1 }; }
                finally {
                    try {
                        if (models_2_1 && !models_2_1.done && (_a = models_2.return)) _a.call(models_2);
                    }
                    finally { if (e_1) throw e_1.error; }
                }
            };
        }
    }, [gameInfo.data, worldId]);
}
