var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var _a;
var _b;
import { createSlice } from '@reduxjs/toolkit';
import { loadState } from "./localStorage";
var initialProgressState = (_b = loadState()) !== null && _b !== void 0 ? _b : { level: {} };
var initalLevelProgressState = { code: "", completed: false };
function addLevelProgress(state, action) {
    if (!state.level[action.payload.game]) {
        state.level[action.payload.game] = {};
    }
    if (!state.level[action.payload.game][action.payload.world]) {
        state.level[action.payload.game][action.payload.world] = {};
    }
    if (!state.level[action.payload.game][action.payload.world][action.payload.level]) {
        state.level[action.payload.game][action.payload.world][action.payload.level] = __assign({}, initalLevelProgressState);
    }
}
export var progressSlice = createSlice({
    name: 'progress',
    initialState: initialProgressState,
    reducers: {
        codeEdited: function (state, action) {
            addLevelProgress(state, action);
            state.level[action.payload.game][action.payload.world][action.payload.level].code = action.payload.code;
            state.level[action.payload.game][action.payload.world][action.payload.level].completed = false;
        },
        changedSelection: function (state, action) {
            addLevelProgress(state, action);
            state.level[action.payload.game][action.payload.world][action.payload.level].selections = action.payload.selections;
        },
        levelCompleted: function (state, action) {
            addLevelProgress(state, action);
            state.level[action.payload.game][action.payload.world][action.payload.level].completed = true;
        },
        deleteProgress: function (state, action) {
            state.level[action.payload.game] = {};
        },
        loadProgress: function (state, action) {
            console.debug("setting data to:\n ".concat(action.payload.data));
            state.level[action.payload.game] = action.payload.data;
        },
    }
});
export function selectLevel(game, world, level) {
    return function (state) {
        if (!state.progress.level[game]) {
            return initalLevelProgressState;
        }
        if (!state.progress.level[game][world]) {
            return initalLevelProgressState;
        }
        if (!state.progress.level[game][world][level]) {
            return initalLevelProgressState;
        }
        return state.progress.level[game][world][level];
    };
}
export function selectCode(game, world, level) {
    return function (state) {
        return selectLevel(game, world, level)(state).code;
    };
}
export function selectSelections(game, world, level) {
    return function (state) {
        return selectLevel(game, world, level)(state).selections;
    };
}
export function selectCompleted(game, world, level) {
    return function (state) {
        return selectLevel(game, world, level)(state).completed;
    };
}
export function selectProgress(game) {
    return function (state) {
        var _a;
        return (_a = state.progress.level[game]) !== null && _a !== void 0 ? _a : null;
    };
}
export var changedSelection = (_a = progressSlice.actions, _a.changedSelection), codeEdited = _a.codeEdited, levelCompleted = _a.levelCompleted, deleteProgress = _a.deleteProgress, loadProgress = _a.loadProgress;
