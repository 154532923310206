var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import * as React from 'react';
import { Button } from './Button';
import { GameIdContext } from '../App';
import { useStore } from 'react-redux';
import { useAppDispatch } from '../hooks';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faUpload, faEraser } from '@fortawesome/free-solid-svg-icons';
import { deleteProgress, selectProgress, loadProgress } from '../state/progress';
var downloadFile = function (_a) {
    var data = _a.data, fileName = _a.fileName, fileType = _a.fileType;
    var blob = new Blob([data], { type: fileType });
    var a = document.createElement('a');
    a.download = fileName;
    a.href = window.URL.createObjectURL(blob);
    var clickEvt = new MouseEvent('click', {
        view: window,
        bubbles: true,
        cancelable: true,
    });
    a.dispatchEvent(clickEvt);
    a.remove();
};
function GameMenu() {
    var _a = __read(React.useState(), 2), file = _a[0], setFile = _a[1];
    var gameId = React.useContext(GameIdContext);
    var store = useStore();
    var _b = __read(React.useState(false), 2), eraseMenu = _b[0], setEraseMenu = _b[1];
    var openEraseMenu = function () { return setEraseMenu(true); };
    var closeEraseMenu = function () { return setEraseMenu(false); };
    var _c = __read(React.useState(false), 2), uploadMenu = _c[0], setUploadMenu = _c[1];
    var openUploadMenu = function () { return setUploadMenu(true); };
    var closeUploadMenu = function () { return setUploadMenu(false); };
    var gameProgress = useSelector(selectProgress(gameId));
    var dispatch = useAppDispatch();
    var downloadProgress = function (e) {
        e.preventDefault();
        downloadFile({
            data: JSON.stringify(gameProgress),
            fileName: "lean4game-".concat(gameId, "-").concat(new Date().toLocaleDateString(), ".json"),
            fileType: 'text/json',
        });
    };
    var handleFileChange = function (e) {
        if (e.target.files) {
            setFile(e.target.files[0]);
        }
    };
    var uploadProgress = function (e) {
        if (!file) {
            return;
        }
        var fileReader = new FileReader();
        fileReader.readAsText(file, "UTF-8");
        fileReader.onload = function (e) {
            var data = JSON.parse(e.target.result.toString());
            console.debug("Json Data", data);
            dispatch(loadProgress({ game: gameId, data: data }));
        };
        closeUploadMenu();
    };
    var eraseProgress = function () {
        dispatch(deleteProgress({ game: gameId }));
        closeEraseMenu();
    };
    var downloadAndErase = function (e) {
        downloadProgress(e);
        eraseProgress();
    };
    return React.createElement("nav", { className: "game-menu" },
        React.createElement(Button, { onClick: downloadProgress, title: "Download game progress", to: "" },
            React.createElement(FontAwesomeIcon, { icon: faDownload })),
        React.createElement(Button, { title: "Load game progress from JSON", onClick: openUploadMenu, to: "" },
            React.createElement(FontAwesomeIcon, { icon: faUpload })),
        React.createElement(Button, { title: "Clear game progress", to: "", onClick: openEraseMenu },
            React.createElement(FontAwesomeIcon, { icon: faEraser })),
        eraseMenu ?
            React.createElement("div", { className: "modal-wrapper" },
                React.createElement("div", { className: "modal-backdrop", onClick: closeEraseMenu }),
                React.createElement("div", { className: "modal" },
                    React.createElement("div", { className: "codicon codicon-close modal-close", onClick: closeEraseMenu }),
                    React.createElement("h2", null, "Delete Progress?"),
                    React.createElement("p", null, "Do you want to delete your saved progress irreversibly?"),
                    React.createElement("p", null, "(This only affects your saved proofs, no levels are ever locked. Saves from other games are not deleted.)"),
                    React.createElement(Button, { onClick: eraseProgress, to: "" }, "Delete"),
                    React.createElement(Button, { onClick: downloadAndErase, to: "" }, "Download & Delete"),
                    React.createElement(Button, { onClick: closeEraseMenu, to: "" }, "Cancel"))) : null,
        uploadMenu ?
            React.createElement("div", { className: "modal-wrapper" },
                React.createElement("div", { className: "modal-backdrop", onClick: closeUploadMenu }),
                React.createElement("div", { className: "modal" },
                    React.createElement("div", { className: "codicon codicon-close modal-close", onClick: closeUploadMenu }),
                    React.createElement("h2", null, "Upload Saved Progress"),
                    React.createElement("p", null, "Select a JSON file with the saved game progress to load your progress."),
                    React.createElement("p", null,
                        React.createElement("b", null, "Warning:"),
                        " This will delete your current game progress! Consider ",
                        React.createElement("a", { className: "download-link", onClick: downloadProgress }, "downloading your current progress"),
                        " first!"),
                    React.createElement("input", { type: "file", onChange: handleFileChange }),
                    React.createElement(Button, { to: "", onClick: uploadProgress }, "Load selected file"))) : null);
}
export default GameMenu;
