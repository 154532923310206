var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import * as React from 'react';
import { FormControlLabel, Switch } from '@mui/material';
import Markdown from '../Markdown';
function Hint(_a) {
    var hint = _a.hint;
    return React.createElement("div", { className: "message info" },
        React.createElement(Markdown, null, hint.text));
}
export function Hints(_a) {
    var hints = _a.hints;
    var _b = __read(React.useState(false), 2), showHints = _b[0], setShowHints = _b[1];
    var openHints = hints.filter(function (hint) { return !hint.hidden; });
    var hiddenHints = hints.filter(function (hint) { return hint.hidden; });
    return React.createElement(React.Fragment, null,
        openHints.map(function (hint) { return React.createElement(Hint, { hint: hint }); }),
        hiddenHints.length > 0 &&
            React.createElement(FormControlLabel, { control: React.createElement(Switch, { checked: showHints, onChange: function () { return setShowHints(function (prev) { return !prev; }); } }), label: "I need help!" }),
        showHints && hiddenHints.map(function (hint) { return React.createElement(Hint, { hint: hint }); }));
}
