var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import * as React from 'react';
import fastIsEqual from 'react-fast-compare';
import { DiagnosticSeverity } from 'vscode-languageserver-protocol';
import { RpcErrorCode } from '@leanprover/infoview-api';
import { basename, escapeHtml, usePausableState, useEvent, addUniqueKeys, DocumentPosition, useServerNotificationState, useEventResult } from '../../../../node_modules/lean4-infoview/src/infoview/util';
import { ConfigContext, EditorContext, LspDiagnosticsContext, VersionContext } from '../../../../node_modules/lean4-infoview/src/infoview/contexts';
import { InteractiveMessage } from '../../../../node_modules/lean4-infoview/src/infoview/traceExplorer';
import { getInteractiveDiagnostics, TaggedText_stripTags } from '@leanprover/infoview-api';
import { RpcContext, useRpcSessionAtPos } from '../../../../node_modules/lean4-infoview/src/infoview/rpcSessions';
import { InputModeContext } from '../Level';
var MessageView = React.memo(function (_a) {
    var _b;
    var _c;
    var uri = _a.uri, diag = _a.diag;
    var ec = React.useContext(EditorContext);
    var fname = escapeHtml(basename(uri));
    var _d = diag.range.start, line = _d.line, character = _d.character;
    var loc = { uri: uri, range: diag.range };
    var text = TaggedText_stripTags(diag.message);
    var severityClass = diag.severity ? (_b = {},
        _b[DiagnosticSeverity.Error] = 'error',
        _b[DiagnosticSeverity.Warning] = 'warning',
        _b[DiagnosticSeverity.Information] = 'information',
        _b[DiagnosticSeverity.Hint] = 'hint',
        _b)[diag.severity] : '';
    var title = "Line ".concat(line + 1, ", Character ").concat(character);
    // Hide "unsolved goals" messages
    var message;
    if ("append" in diag.message && "text" in diag.message.append[0] &&
        ((_c = diag.message) === null || _c === void 0 ? void 0 : _c.append[0].text) === "unsolved goals") {
        message = diag.message.append[0];
    }
    else {
        message = diag.message;
    }
    var commandLineMode = React.useContext(InputModeContext).commandLineMode;
    return (
    // <details open>
    // <summary className={severityClass + ' mv2 pointer'}>{title}
    //     <span className="fr">
    //         <a className="link pointer mh2 dim codicon codicon-go-to-file"
    //            onClick={e => { e.preventDefault(); void ec.revealLocation(loc); }}
    //            title="reveal file location"></a>
    //         <a className="link pointer mh2 dim codicon codicon-quote"
    //            data-id="copy-to-comment"
    //            onClick={e => {e.preventDefault(); void ec.copyToComment(text)}}
    //            title="copy message to comment"></a>
    //         <a className="link pointer mh2 dim codicon codicon-clippy"
    //            onClick={e => {e.preventDefault(); void ec.api.copyToClipboard(text)}}
    //            title="copy message to clipboard"></a>
    //     </span>
    // </summary>
    React.createElement("div", { className: severityClass + ' ml1 message' },
        !commandLineMode && React.createElement("p", { className: "mv2" }, title),
        React.createElement("pre", { className: "font-code pre-wrap" },
            React.createElement(InteractiveMessage, { fmt: message })))
    // </details>
    );
}, fastIsEqual);
function mkMessageViewProps(uri, messages) {
    var views = messages
        .sort(function (msga, msgb) {
        var _a, _b;
        var a = ((_a = msga.fullRange) === null || _a === void 0 ? void 0 : _a.end) || msga.range.end;
        var b = ((_b = msgb.fullRange) === null || _b === void 0 ? void 0 : _b.end) || msgb.range.end;
        return a.line === b.line ? a.character - b.character : a.line - b.line;
    }).map(function (m) {
        return { uri: uri, diag: m };
    });
    return addUniqueKeys(views, function (v) { return DocumentPosition.toString(__assign({ uri: v.uri }, v.diag.range.start)); });
}
/** Shows the given messages assuming they are for the given file. */
export var MessagesList = React.memo(function (_a) {
    var uri = _a.uri, messages = _a.messages;
    var should_hide = messages.length === 0;
    if (should_hide) {
        return React.createElement(React.Fragment, null);
    }
    return (React.createElement("div", null, mkMessageViewProps(uri, messages).map(function (m) { return React.createElement(MessageView, __assign({}, m)); })));
});
function lazy(f) {
    var state;
    return function () {
        if (!state)
            state = { t: f() };
        return state.t;
    };
}
/** Displays all messages for the specified file. Can be paused. */
export function AllMessages() {
    var _this = this;
    var ec = React.useContext(EditorContext);
    var sv = React.useContext(VersionContext);
    var curPos = useEventResult(ec.events.changedCursorLocation, function (loc) { return loc ? __assign({ uri: loc.uri }, loc.range.start) : undefined; });
    var rs0 = useRpcSessionAtPos({ uri: curPos.uri, line: 0, character: 0 });
    var dc = React.useContext(LspDiagnosticsContext);
    var config = React.useContext(ConfigContext);
    var diags0 = dc.get(curPos.uri) || [];
    var iDiags0 = React.useMemo(function () { return lazy(function () { return __awaiter(_this, void 0, void 0, function () {
        var diags_1, err_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, getInteractiveDiagnostics(rs0)];
                case 1:
                    diags_1 = _a.sent();
                    if (diags_1.length > 0) {
                        return [2 /*return*/, diags_1];
                    }
                    return [3 /*break*/, 3];
                case 2:
                    err_1 = _a.sent();
                    if ((err_1 === null || err_1 === void 0 ? void 0 : err_1.code) === RpcErrorCode.ContentModified) {
                        // Document has been changed since we made the request. This can happen
                        // while typing quickly. When the server catches up on next edit, it will
                        // send new diagnostics to which the infoview responds by calling
                        // `getInteractiveDiagnostics` again.
                    }
                    else {
                        console.log('getInteractiveDiagnostics error ', err_1);
                    }
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/, diags0.map(function (d) { return (__assign(__assign({}, d), { message: { text: d.message } })); })];
            }
        });
    }); }); }, [sv, rs0, curPos.uri, diags0]);
    var _a = __read(usePausableState(false, [curPos.uri, rs0, diags0, iDiags0]), 3), _b = _a[0], isPaused = _b.isPaused, setPaused = _b.setPaused, _c = __read(_a[1], 4), uri = _c[0], rs = _c[1], diags = _c[2], iDiags = _c[3], _ = _a[2];
    // Fetch interactive diagnostics when we're entering the paused state
    // (if they haven't already been fetched before)
    React.useEffect(function () { if (isPaused) {
        void iDiags();
    } }, [iDiags, isPaused]);
    var setOpenRef = React.useRef();
    useEvent(ec.events.requestedAction, function (act) {
        if (act.kind === 'toggleAllMessages' && setOpenRef.current !== undefined) {
            setOpenRef.current(function (t) { return !t; });
        }
    });
    return (React.createElement(RpcContext.Provider, { value: rs },
        React.createElement(AllMessagesBody, { uri: curPos.uri, key: curPos.uri, messages: iDiags0 })));
}
/** We factor out the body of {@link AllMessages} which lazily fetches its contents only when expanded. */
function AllMessagesBody(_a) {
    var uri = _a.uri, messages = _a.messages;
    var _b = __read(React.useState(undefined), 2), msgs = _b[0], setMsgs = _b[1];
    React.useEffect(function () { void messages().then(setMsgs); }, [messages]);
    if (msgs === undefined)
        return React.createElement("div", null, "Loading messages...");
    else
        return React.createElement(MessagesList, { uri: uri, messages: msgs });
}
/**
 * Provides a `LspDiagnosticsContext` which stores the latest version of the
 * diagnostics as sent by the publishDiagnostics notification.
 */
export function WithLspDiagnosticsContext(_a) {
    var _this = this;
    var children = _a.children;
    var _b = __read(useServerNotificationState('textDocument/publishDiagnostics', new Map(), function (params) { return __awaiter(_this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, function (diags) {
                    return new Map(diags).set(params.uri, params.diagnostics);
                }];
        });
    }); }, []), 2), allDiags = _b[0], _0 = _b[1];
    return React.createElement(LspDiagnosticsContext.Provider, { value: allDiags }, children);
}
/** Embeds a non-interactive diagnostic into the type `InteractiveDiagnostic`. */
export function lspDiagToInteractive(diag) {
    return __assign(__assign({}, diag), { message: { text: diag.message } });
}
