var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import * as React from 'react';
import { useState, useEffect } from 'react';
import './inventory.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock, faBan } from '@fortawesome/free-solid-svg-icons';
import Markdown from './Markdown';
import { useLoadDocQuery } from '../state/api';
import { GameIdContext } from '../App';
export function Inventory(_a) {
    var levelInfo = _a.levelInfo, setInventoryDoc = _a.setInventoryDoc;
    // TODO: This seems like a useless wrapper to me
    function openDoc(name, type) {
        setInventoryDoc({ name: name, type: type });
    }
    return (React.createElement("div", { className: "inventory" },
        React.createElement("h2", null, "Tactics"),
        React.createElement(InventoryList, { items: levelInfo === null || levelInfo === void 0 ? void 0 : levelInfo.tactics, docType: "Tactic", openDoc: openDoc }),
        React.createElement("h2", null, "Definitions"),
        React.createElement(InventoryList, { items: levelInfo === null || levelInfo === void 0 ? void 0 : levelInfo.definitions, docType: "Definition", openDoc: openDoc }),
        React.createElement("h2", null, "Lemmas"),
        React.createElement(InventoryList, { items: levelInfo === null || levelInfo === void 0 ? void 0 : levelInfo.lemmas, docType: "Lemma", openDoc: openDoc, defaultTab: levelInfo === null || levelInfo === void 0 ? void 0 : levelInfo.lemmaTab, level: levelInfo })));
}
function InventoryList(_a) {
    // TODO: `level` is only used in the `useEffect` below to check if a new level has
    // been loaded. Is there a better way to observe this?
    var e_1, _b;
    var items = _a.items, docType = _a.docType, openDoc = _a.openDoc, _c = _a.defaultTab, defaultTab = _c === void 0 ? null : _c, _d = _a.level, level = _d === void 0 ? undefined : _d;
    var categorySet = new Set();
    try {
        for (var items_1 = __values(items), items_1_1 = items_1.next(); !items_1_1.done; items_1_1 = items_1.next()) {
            var item = items_1_1.value;
            categorySet.add(item.category);
        }
    }
    catch (e_1_1) { e_1 = { error: e_1_1 }; }
    finally {
        try {
            if (items_1_1 && !items_1_1.done && (_b = items_1.return)) _b.call(items_1);
        }
        finally { if (e_1) throw e_1.error; }
    }
    var categories = Array.from(categorySet).sort();
    var _e = __read(useState(defaultTab), 2), tab = _e[0], setTab = _e[1];
    useEffect(function () {
        // If the level specifies `LemmaTab "Nat"`, we switch to this tab on loading.
        // `defaultTab` is `null` or `undefined` otherwise, in which case we don't want to switch.
        if (defaultTab) {
            setTab(defaultTab);
        }
    }, [level]);
    return React.createElement(React.Fragment, null,
        categories.length > 1 &&
            React.createElement("div", { className: "tab-bar" }, categories.map(function (cat) {
                return React.createElement("div", { className: "tab ".concat(cat == (tab !== null && tab !== void 0 ? tab : categories[0]) ? "active" : ""), onClick: function () { setTab(cat); } }, cat);
            })),
        React.createElement("div", { className: "inventory-list" }, __spreadArray([], __read(items), false).sort(
        // Sort entries `available > disabled > locked`.
        function (x, y) { return +x.locked - +y.locked || +x.disabled - +y.disabled; }).map(function (item) {
            if ((tab !== null && tab !== void 0 ? tab : categories[0]) == item.category) {
                return React.createElement(InventoryItem, { key: item.name, showDoc: function () { openDoc(item.name, docType); }, name: item.name, displayName: item.displayName, locked: item.locked, disabled: item.disabled, newly: item.new });
            }
        })));
}
function InventoryItem(_a) {
    var name = _a.name, displayName = _a.displayName, locked = _a.locked, disabled = _a.disabled, newly = _a.newly, showDoc = _a.showDoc;
    var icon = locked ? React.createElement(FontAwesomeIcon, { icon: faLock }) :
        disabled ? React.createElement(FontAwesomeIcon, { icon: faBan }) : "";
    var className = locked ? "locked" : disabled ? "disabled" : newly ? "new" : "";
    var title = locked ? "Not unlocked yet" :
        disabled ? "Not available in this level" : "";
    var handleClick = function () {
        if (!locked && !disabled) {
            showDoc();
        }
    };
    return React.createElement("div", { className: "item ".concat(className), onClick: handleClick, title: title },
        icon,
        " ",
        displayName);
}
export function Documentation(_a) {
    var _b, _c, _d;
    var name = _a.name, type = _a.type;
    var gameId = React.useContext(GameIdContext);
    var doc = useLoadDocQuery({ game: gameId, type: type, name: name });
    return React.createElement(React.Fragment, null,
        React.createElement("h2", { className: "doc" }, (_b = doc.data) === null || _b === void 0 ? void 0 : _b.displayName),
        React.createElement("p", null,
            React.createElement("code", null, (_c = doc.data) === null || _c === void 0 ? void 0 : _c.statement)),
        React.createElement(Markdown, null, (_d = doc.data) === null || _d === void 0 ? void 0 : _d.content));
}
