/* Mostly copied from https://github.com/leanprover/vscode-lean4/blob/master/lean4-infoview/src/infoview/infos.tsx */
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import * as React from 'react';
import { TextDocumentContentChangeEvent } from 'vscode-languageserver-protocol';
import { EditorContext } from '../../../../node_modules/lean4-infoview/src/infoview/contexts';
import { DocumentPosition, PositionHelpers, useClientNotificationEffect, useClientNotificationState, useEvent, useEventResult } from '../../../../node_modules/lean4-infoview/src/infoview/util';
import { Info } from './info';
/** Manages and displays pinned infos, as well as info for the current location. */
export function Infos() {
    var ec = React.useContext(EditorContext);
    // Update pins when the document changes. In particular, when edits are made
    // earlier in the text such that a pin has to move up or down.
    var _a = __read(useClientNotificationState('textDocument/didChange', new Array(), function (pinnedPositions, params) {
        if (pinnedPositions.length === 0)
            return pinnedPositions;
        var changed = false;
        var newPins = pinnedPositions.map(function (pin) {
            var e_1, _a, e_2, _b;
            if (pin.uri !== params.textDocument.uri)
                return pin;
            // NOTE(WN): It's important to make a clone here, otherwise this
            // actually mutates the pin. React state updates must be pure.
            // See https://github.com/facebook/react/issues/12856
            var newPin = __assign({}, pin);
            try {
                for (var _c = __values(params.contentChanges), _d = _c.next(); !_d.done; _d = _c.next()) {
                    var chg = _d.value;
                    if (!TextDocumentContentChangeEvent.isIncremental(chg)) {
                        changed = true;
                        return null;
                    }
                    if (PositionHelpers.isLessThanOrEqual(newPin, chg.range.start))
                        continue;
                    // We can assume chg.range.start < pin
                    // If the pinned position is replaced with new text, just delete the pin.
                    if (PositionHelpers.isLessThanOrEqual(newPin, chg.range.end)) {
                        changed = true;
                        return null;
                    }
                    var oldPin = __assign({}, newPin);
                    // How many lines before the pin position were added by the change.
                    // Can be negative when more lines are removed than added.
                    var additionalLines = 0;
                    var lastLineLen = chg.range.start.character;
                    try {
                        for (var _e = (e_2 = void 0, __values(chg.text)), _f = _e.next(); !_f.done; _f = _e.next()) {
                            var c = _f.value;
                            if (c === '\n') {
                                additionalLines++;
                                lastLineLen = 0;
                            }
                            else
                                lastLineLen++;
                        }
                    }
                    catch (e_2_1) { e_2 = { error: e_2_1 }; }
                    finally {
                        try {
                            if (_f && !_f.done && (_b = _e.return)) _b.call(_e);
                        }
                        finally { if (e_2) throw e_2.error; }
                    }
                    // Subtract lines that were already present
                    additionalLines -= (chg.range.end.line - chg.range.start.line);
                    newPin.line += additionalLines;
                    if (oldPin.line < chg.range.end.line) {
                        // Should never execute by the <= check above.
                        throw new Error('unreachable code reached');
                    }
                    else if (oldPin.line === chg.range.end.line) {
                        newPin.character = lastLineLen + (oldPin.character - chg.range.end.character);
                    }
                }
            }
            catch (e_1_1) { e_1 = { error: e_1_1 }; }
            finally {
                try {
                    if (_d && !_d.done && (_a = _c.return)) _a.call(_c);
                }
                finally { if (e_1) throw e_1.error; }
            }
            if (!DocumentPosition.isEqual(newPin, pin))
                changed = true;
            // NOTE(WN): We maintain the `key` when a pin is moved around to maintain
            // its component identity and minimise flickering.
            return newPin;
        });
        if (changed)
            return newPins.filter(function (p) { return p !== null; });
        return pinnedPositions;
    }, []), 2), pinnedPositions = _a[0], setPinnedPositions = _a[1];
    // Remove pins for closed documents
    useClientNotificationEffect('textDocument/didClose', function (params) {
        setPinnedPositions(function (pinnedPositions) { return pinnedPositions.filter(function (p) { return p.uri !== params.textDocument.uri; }); });
    }, []);
    var curPos = useEventResult(ec.events.changedCursorLocation, function (loc) { return loc ? __assign({ uri: loc.uri }, loc.range.start) : undefined; });
    // Update pins on UI actions
    var pinKey = React.useRef(0);
    var isPinned = function (pinnedPositions, pos) {
        return pinnedPositions.some(function (p) { return DocumentPosition.isEqual(p, pos); });
    };
    var pin = React.useCallback(function (pos) {
        setPinnedPositions(function (pinnedPositions) {
            if (isPinned(pinnedPositions, pos))
                return pinnedPositions;
            pinKey.current += 1;
            return __spreadArray(__spreadArray([], __read(pinnedPositions), false), [__assign(__assign({}, pos), { key: pinKey.current.toString() })], false);
        });
    }, []);
    var unpin = React.useCallback(function (pos) {
        setPinnedPositions(function (pinnedPositions) {
            if (!isPinned(pinnedPositions, pos))
                return pinnedPositions;
            return pinnedPositions.filter(function (p) { return !DocumentPosition.isEqual(p, pos); });
        });
    }, []);
    // Toggle pin at current position when the editor requests it
    useEvent(ec.events.requestedAction, function (act) {
        if (act.kind !== 'togglePin')
            return;
        if (!curPos)
            return;
        setPinnedPositions(function (pinnedPositions) {
            if (isPinned(pinnedPositions, curPos)) {
                return pinnedPositions.filter(function (p) { return !DocumentPosition.isEqual(p, curPos); });
            }
            else {
                pinKey.current += 1;
                return __spreadArray(__spreadArray([], __read(pinnedPositions), false), [__assign(__assign({}, curPos), { key: pinKey.current.toString() })], false);
            }
        });
    }, [curPos === null || curPos === void 0 ? void 0 : curPos.uri, curPos === null || curPos === void 0 ? void 0 : curPos.line, curPos === null || curPos === void 0 ? void 0 : curPos.character]);
    var infoProps = pinnedPositions.map(function (pos) { return ({ kind: 'pin', onPin: unpin, pos: pos, key: pos.key }); });
    if (curPos)
        infoProps.push({ kind: 'cursor', onPin: pin, key: 'cursor' });
    return React.createElement("div", null,
        infoProps.map(function (ps) { return React.createElement(Info, __assign({}, ps)); }),
        !curPos && React.createElement("p", null, "Click somewhere in the Lean file to enable the infoview."));
}
