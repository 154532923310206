/* Mostly copied from https://github.com/leanprover/vscode-lean4/blob/master/lean4-infoview/src/infoview/info.tsx */
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import * as React from 'react';
import { goalsToString, Goal, MainAssumptions, OtherGoals, ProofDisplay } from './goals';
import { basename, DocumentPosition, RangeHelpers, useEvent, usePausableState, discardMethodNotFound, mapRpcError, useAsyncWithTrigger } from '../../../../node_modules/lean4-infoview/src/infoview/util';
import { ConfigContext, EditorContext, LspDiagnosticsContext, ProgressContext } from '../../../../node_modules/lean4-infoview/src/infoview/contexts';
import { AllMessages, lspDiagToInteractive } from './messages';
import { getInteractiveTermGoal, Widget_getWidgets, isRpcError, RpcErrorCode, getInteractiveDiagnostics } from '@leanprover/infoview-api';
import { PanelWidgetDisplay } from '../../../../node_modules/lean4-infoview/src/infoview/userWidget';
import { RpcContext, useRpcSessionAtPos } from '../../../../node_modules/lean4-infoview/src/infoview/rpcSessions';
import { GoalsLocation, LocationsContext } from '../../../../node_modules/lean4-infoview/src/infoview/goalLocation';
import { CircularProgress } from '@mui/material';
import { MonacoEditorContext } from '../Level';
var InfoStatusBar = React.memo(function (props) {
    var kind = props.kind, onPin = props.onPin, status = props.status, pos = props.pos, isPaused = props.isPaused, setPaused = props.setPaused, triggerUpdate = props.triggerUpdate;
    var ec = React.useContext(EditorContext);
    var statusColTable = {
        'updating': 'gold ',
        'error': 'dark-red ',
        'ready': '',
    };
    var statusColor = statusColTable[status];
    var locationString = "".concat(basename(pos.uri), ":").concat(pos.line + 1, ":").concat(pos.character);
    var isPinned = kind === 'pin';
    return (React.createElement("summary", { style: { transition: 'color 0.5s ease' }, className: 'mv2 pointer ' + statusColor },
        locationString,
        isPinned && !isPaused && ' (pinned)',
        !isPinned && isPaused && ' (paused)',
        isPinned && isPaused && ' (pinned and paused)',
        React.createElement("span", { className: 'fr' },
            isPinned &&
                React.createElement("a", { className: 'link pointer mh2 dim codicon codicon-go-to-file', "data-id": 'reveal-file-location', onClick: function (e) { e.preventDefault(); void ec.revealPosition(pos); }, title: 'reveal file location' }),
            React.createElement("a", { className: 'link pointer mh2 dim codicon ' + (isPinned ? 'codicon-pinned ' : 'codicon-pin '), "data-id": 'toggle-pinned', onClick: function (e) { e.preventDefault(); onPin(pos); }, title: isPinned ? 'unpin' : 'pin' }),
            React.createElement("a", { className: 'link pointer mh2 dim codicon ' + (isPaused ? 'codicon-debug-continue ' : 'codicon-debug-pause '), "data-id": 'toggle-paused', onClick: function (e) { e.preventDefault(); setPaused(!isPaused); }, title: isPaused ? 'continue updating' : 'pause updating' }),
            React.createElement("a", { className: 'link pointer mh2 dim codicon codicon-refresh', "data-id": 'update', onClick: function (e) { e.preventDefault(); void triggerUpdate(); }, title: 'update' }))));
});
var InfoDisplayContent = React.memo(function (props) {
    var pos = props.pos, messages = props.messages, goals = props.goals, termGoal = props.termGoal, error = props.error, userWidgets = props.userWidgets, triggerUpdate = props.triggerUpdate, isPaused = props.isPaused, setPaused = props.setPaused, proof = props.proof;
    var hasWidget = userWidgets.length > 0;
    var hasError = !!error;
    var hasMessages = messages.length !== 0;
    var nothingToShow = !hasError && !goals && !termGoal && !hasMessages && !hasWidget;
    var _a = __read(React.useState([]), 2), selectedLocs = _a[0], setSelectedLocs = _a[1];
    React.useEffect(function () { return setSelectedLocs([]); }, [pos.uri, pos.line, pos.character]);
    var locs = React.useMemo(function () { return ({
        isSelected: function (l) { return selectedLocs.some(function (v) { return GoalsLocation.isEqual(v, l); }); },
        setSelected: function (l, act) { return setSelectedLocs(function (ls) {
            // We ensure that `selectedLocs` maintains its reference identity if the selection
            // status of `l` didn't change.
            var newLocs = ls.filter(function (v) { return !GoalsLocation.isEqual(v, l); });
            var wasSelected = newLocs.length !== ls.length;
            var isSelected = typeof act === 'function' ? act(wasSelected) : act;
            if (isSelected)
                newLocs.push(l);
            return wasSelected === isSelected ? ls : newLocs;
        }); },
        subexprTemplate: undefined
    }); }, [selectedLocs]);
    var goalFilter = { reverse: false, showType: true, showInstance: true, showHiddenAssumption: true, showLetValue: true };
    /* Adding {' '} to manage string literals properly: https://reactjs.org/docs/jsx-in-depth.html#string-literals-1 */
    return React.createElement(React.Fragment, null,
        hasError &&
            React.createElement("div", { className: 'error', key: 'errors' },
                "Error updating:",
                ' ',
                error,
                ".",
                React.createElement("a", { className: 'link pointer dim', onClick: function (e) { e.preventDefault(); void triggerUpdate(); } },
                    ' ',
                    "Try again.")),
        React.createElement(LocationsContext.Provider, { value: locs },
            React.createElement("div", { className: "goals-section" }, goals && goals.goals.length > 0 && React.createElement(React.Fragment, null,
                React.createElement(MainAssumptions, { filter: goalFilter, key: 'mainGoal', goals: goals.goals }),
                React.createElement(ProofDisplay, { proof: proof }),
                React.createElement(OtherGoals, { filter: goalFilter, goals: goals.goals }))),
            React.createElement("div", null, goals && (goals.goals.length > 0
                ? React.createElement(Goal, { commandLine: true, filter: goalFilter, key: 'mainGoal', goal: goals.goals[0], showHints: true })
                : React.createElement("div", { className: "goals-section-title" }, "No Goals")))),
        userWidgets.map(function (widget) {
            var _a;
            return React.createElement("details", { key: "widget::".concat(widget.id, "::").concat((_a = widget.range) === null || _a === void 0 ? void 0 : _a.toString()), open: true },
                React.createElement("summary", { className: 'mv2 pointer' }, widget.name),
                React.createElement(PanelWidgetDisplay, { pos: pos, goals: goals ? goals.goals.map(function (goal) { return goal; }) : [], termGoal: termGoal, selectedLocations: selectedLocs, widget: widget }));
        }),
        nothingToShow && (isPaused ?
            /* Adding {' '} to manage string literals properly: https://reactjs.org/docs/jsx-in-depth.html#string-literals-1 */
            React.createElement("span", null,
                "Updating is paused.",
                ' ',
                React.createElement("a", { className: 'link pointer dim', onClick: function (e) { e.preventDefault(); void triggerUpdate(); } }, "Refresh"),
                ' ',
                "or ",
                React.createElement("a", { className: 'link pointer dim', onClick: function (e) { e.preventDefault(); setPaused(false); } }, "resume updating"),
                ' ',
                "to see information.") :
            React.createElement(React.Fragment, null,
                React.createElement(CircularProgress, null),
                React.createElement("div", null, "Loading goal..."))),
        React.createElement(AllMessages, null));
});
/** Displays goal state and messages. Can be paused. */
function InfoDisplay(props0) {
    var _this = this;
    // Used to update the paused state *just once* if it is paused,
    // but a display update is triggered
    var _a = __read(React.useState(false), 2), shouldRefresh = _a[0], setShouldRefresh = _a[1];
    var _b = __read(usePausableState(false, props0), 3), _c = _b[0], isPaused = _c.isPaused, setPaused = _c.setPaused, props = _b[1], propsRef = _b[2];
    if (shouldRefresh) {
        propsRef.current = props0;
        setShouldRefresh(false);
    }
    var triggerDisplayUpdate = function () { return __awaiter(_this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, props0.triggerUpdate()];
                case 1:
                    _a.sent();
                    setShouldRefresh(true);
                    return [2 /*return*/];
            }
        });
    }); };
    var kind = props.kind, goals = props.goals, rpcSess = props.rpcSess;
    var ec = React.useContext(EditorContext);
    // If we are the cursor infoview, then we should subscribe to
    // some commands from the editor extension
    var isCursor = kind === 'cursor';
    useEvent(ec.events.requestedAction, function (act) {
        if (!isCursor)
            return;
        if (act.kind !== 'copyToComment')
            return;
        if (goals)
            void ec.copyToComment(goalsToString(goals));
    }, [goals]);
    useEvent(ec.events.requestedAction, function (act) {
        if (!isCursor)
            return;
        if (act.kind !== 'togglePaused')
            return;
        setPaused(function (isPaused) { return !isPaused; });
    });
    var editor = React.useContext(MonacoEditorContext);
    return (React.createElement(RpcContext.Provider, { value: rpcSess },
        React.createElement("div", null,
            React.createElement(InfoDisplayContent, __assign({}, props, { proof: editor.getValue(), triggerUpdate: triggerDisplayUpdate, isPaused: isPaused, setPaused: setPaused })))));
}
/** Fetches info from the server and renders an {@link InfoDisplay}. */
export function Info(props) {
    if (props.kind === 'cursor')
        return React.createElement(InfoAtCursor, __assign({}, props));
    else
        return React.createElement(InfoAux, __assign({}, props, { pos: props.pos }));
}
function InfoAtCursor(props) {
    var ec = React.useContext(EditorContext);
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    var _a = __read(React.useState(ec.events.changedCursorLocation.current), 2), curLoc = _a[0], setCurLoc = _a[1];
    useEvent(ec.events.changedCursorLocation, function (loc) { return loc && setCurLoc(loc); }, []);
    var pos = __assign({ uri: curLoc.uri }, curLoc.range.start);
    return React.createElement(InfoAux, __assign({}, props, { pos: pos }));
}
function useIsProcessingAt(p) {
    var allProgress = React.useContext(ProgressContext);
    var processing = allProgress.get(p.uri);
    if (!processing)
        return false;
    return processing.some(function (i) { return RangeHelpers.contains(i.range, p); });
}
function InfoAux(props) {
    var config = React.useContext(ConfigContext);
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    var pos = props.pos;
    var rpcSess = useRpcSessionAtPos(pos);
    // Compute the LSP diagnostics at this info's position. We try to ensure that if these remain
    // the same, then so does the identity of `lspDiagsHere` so that it can be used as a dep.
    var lspDiags = React.useContext(LspDiagnosticsContext);
    var _a = __read(React.useState([]), 2), lspDiagsHere = _a[0], setLspDiagsHere = _a[1];
    React.useEffect(function () {
        // Note: the curly braces are important. https://medium.com/geekculture/react-uncaught-typeerror-destroy-is-not-a-function-192738a6e79b
        setLspDiagsHere(function (diags0) {
            var diagPred = function (d) {
                return RangeHelpers.contains(d.range, pos, config.allErrorsOnLine);
            };
            var newDiags = (lspDiags.get(pos.uri) || []).filter(diagPred);
            if (newDiags.length === diags0.length && newDiags.every(function (d, i) { return d === diags0[i]; }))
                return diags0;
            return newDiags;
        });
    }, [lspDiags, pos.uri, pos.line, pos.character, config.allErrorsOnLine]);
    var serverIsProcessing = useIsProcessingAt(pos);
    // This is a virtual dep of the info-requesting function. It is bumped whenever the Lean server
    // indicates that another request should be made. Bumping it dirties the dep state of
    // `useAsyncWithTrigger` below, causing the `useEffect` lower down in this component to
    // make the request. We cannot simply call `triggerUpdateCore` because `useAsyncWithTrigger`
    // does not support reentrancy like that.
    var _b = __read(React.useState(0), 2), updaterTick = _b[0], setUpdaterTick = _b[1];
    var _c = __read(useAsyncWithTrigger(function () { return new Promise(function (resolve, reject) {
        var goalsReq = rpcSess.call('Game.getInteractiveGoals', DocumentPosition.toTdpp(pos));
        var termGoalReq = getInteractiveTermGoal(rpcSess, DocumentPosition.toTdpp(pos));
        var widgetsReq = Widget_getWidgets(rpcSess, pos).catch(discardMethodNotFound);
        var messagesReq = getInteractiveDiagnostics(rpcSess, { start: pos.line, end: pos.line + 1 })
            // fall back to non-interactive diagnostics when lake fails
            // (see https://github.com/leanprover/vscode-lean4/issues/90)
            .then(function (diags) { return diags.length === 0 ? lspDiagsHere.map(lspDiagToInteractive) : diags; });
        // While `lake print-paths` is running, the output of Lake is shown as
        // info diagnostics on line 1.  However, all RPC requests block until
        // Lake is finished, so we don't see these diagnostics while Lake is
        // building.  Therefore we show the LSP diagnostics on line 1 if the
        // server does not respond within half a second.
        if (pos.line === 0 && lspDiagsHere.length) {
            setTimeout(function () { return resolve({
                pos: pos,
                status: 'updating',
                messages: lspDiagsHere.map(lspDiagToInteractive),
                goals: undefined,
                termGoal: undefined,
                error: undefined,
                userWidgets: [],
                rpcSess: rpcSess
            }); }, 500);
        }
        // NB: it is important to await await reqs at once, otherwise
        // if both throw then one exception becomes unhandled.
        Promise.all([goalsReq, termGoalReq, widgetsReq, messagesReq]).then(function (_a) {
            var _b;
            var _c = __read(_a, 4), goals = _c[0], termGoal = _c[1], userWidgets = _c[2], messages = _c[3];
            return resolve({
                pos: pos,
                status: 'ready',
                messages: messages,
                goals: goals,
                termGoal: termGoal,
                error: undefined,
                userWidgets: (_b = userWidgets === null || userWidgets === void 0 ? void 0 : userWidgets.widgets) !== null && _b !== void 0 ? _b : [],
                rpcSess: rpcSess
            });
        }, function (ex) {
            if ((ex === null || ex === void 0 ? void 0 : ex.code) === RpcErrorCode.ContentModified ||
                (ex === null || ex === void 0 ? void 0 : ex.code) === RpcErrorCode.RpcNeedsReconnect) {
                // Document has been changed since we made the request, or we need to reconnect
                // to the RPC sessions. Try again.
                setUpdaterTick(function (t) { return t + 1; });
                reject('retry');
            }
            var errorString = '';
            if (typeof ex === 'string') {
                errorString = ex;
            }
            else if (isRpcError(ex)) {
                errorString = mapRpcError(ex).message;
            }
            else if (ex instanceof Error) {
                errorString = ex.toString();
            }
            else {
                errorString = "Unrecognized error: ".concat(JSON.stringify(ex));
            }
            resolve({
                pos: pos,
                status: 'error',
                messages: lspDiagsHere.map(lspDiagToInteractive),
                goals: undefined,
                termGoal: undefined,
                error: "Error fetching goals: ".concat(errorString),
                userWidgets: [],
                rpcSess: rpcSess
            });
        });
    }); }, [updaterTick, pos.uri, pos.line, pos.character, rpcSess, serverIsProcessing, lspDiagsHere]), 2), state = _c[0], triggerUpdateCore = _c[1];
    // We use a timeout to debounce info requests. Whenever a request is already scheduled
    // but something happens that warrants a request for newer info, we cancel the old request
    // and schedule just the new one.
    var updaterTimeout = React.useRef();
    var clearUpdaterTimeout = function () {
        if (updaterTimeout.current) {
            window.clearTimeout(updaterTimeout.current);
            updaterTimeout.current = undefined;
        }
    };
    var triggerUpdate = React.useCallback(function () { return new Promise(function (resolve) {
        clearUpdaterTimeout();
        var tm = window.setTimeout(function () {
            void triggerUpdateCore().then(resolve);
            updaterTimeout.current = undefined;
        }, config.debounceTime);
        // Hack: even if the request is cancelled, the promise should resolve so that no `await`
        // is left waiting forever. We ensure this happens in a simple way.
        window.setTimeout(resolve, config.debounceTime);
        updaterTimeout.current = tm;
    }); }, [triggerUpdateCore, config.debounceTime]);
    var _d = __read(React.useState({
        pos: pos,
        status: 'updating',
        messages: [],
        goals: undefined,
        termGoal: undefined,
        error: undefined,
        userWidgets: [],
        rpcSess: rpcSess,
        triggerUpdate: triggerUpdate
    }), 2), displayProps = _d[0], setDisplayProps = _d[1];
    // Propagates changes in the state of async info requests to the display props,
    // and re-requests info if needed.
    // This effect triggers new requests for info whenever need. It also propagates changes
    // in the state of the `useAsyncWithTrigger` to the displayed props.
    React.useEffect(function () {
        if (state.state === 'notStarted')
            void triggerUpdate();
        else if (state.state === 'loading')
            setDisplayProps(function (dp) { return (__assign(__assign({}, dp), { status: 'updating' })); });
        else if (state.state === 'resolved') {
            setDisplayProps(__assign(__assign({}, state.value), { triggerUpdate: triggerUpdate }));
        }
        else if (state.state === 'rejected' && state.error !== 'retry') {
            // The code inside `useAsyncWithTrigger` may only ever reject with a `retry` exception.
            console.warn('Unreachable code reached with error: ', state.error);
        }
    }, [state]);
    return React.createElement(InfoDisplay, __assign({ kind: props.kind, onPin: props.onPin }, displayProps));
}
