var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import * as React from 'react';
import { useRef, useState, useEffect } from 'react';
import { useServerNotificationEffect } from '../../../../node_modules/lean4-infoview/src/infoview/util';
import * as monaco from 'monaco-editor/esm/vs/editor/editor.api.js';
import { DiagnosticSeverity } from 'vscode-languageserver-protocol';
import { InputModeContext, MonacoEditorContext } from '../Level';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWandMagicSparkles } from '@fortawesome/free-solid-svg-icons';
import { AbbreviationRewriter } from 'lean4web/client/src/editor/abbreviation/rewriter/AbbreviationRewriter';
import { AbbreviationProvider } from 'lean4web/client/src/editor/abbreviation/AbbreviationProvider';
import { Registry } from 'monaco-textmate'; // peer dependency
import { wireTmGrammars } from 'monaco-editor-textmate';
import * as leanSyntax from 'lean4web/client/src/syntaxes/lean.json';
import * as leanMarkdownSyntax from 'lean4web/client/src/syntaxes/lean-markdown.json';
import * as codeblockSyntax from 'lean4web/client/src/syntaxes/codeblock.json';
import languageConfig from 'lean4/language-configuration.json';
/* We register a new language `leancmd` that looks like lean4, but does not use the lsp server. */
// register Monaco languages
monaco.languages.register({
    id: 'lean4cmd',
    extensions: ['.leancmd']
});
// map of monaco "language id's" to TextMate scopeNames
var grammars = new Map();
grammars.set('lean4', 'source.lean');
grammars.set('lean4cmd', 'source.lean');
var registry = new Registry({
    getGrammarDefinition: function (scopeName) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            if (scopeName === 'source.lean') {
                return [2 /*return*/, {
                        format: 'json',
                        content: JSON.stringify(leanSyntax)
                    }];
            }
            else if (scopeName === 'source.lean.markdown') {
                return [2 /*return*/, {
                        format: 'json',
                        content: JSON.stringify(leanMarkdownSyntax)
                    }];
            }
            else {
                return [2 /*return*/, {
                        format: 'json',
                        content: JSON.stringify(codeblockSyntax)
                    }];
            }
            return [2 /*return*/];
        });
    }); }
});
wireTmGrammars(monaco, registry, grammars);
var config = __assign({}, languageConfig);
config.autoClosingPairs = config.autoClosingPairs.map(function (pair) { return { 'open': pair[0], 'close': pair[1] }; });
monaco.languages.setLanguageConfiguration('lean4cmd', config);
export function CommandLine() {
    /** Reference to the hidden multi-line editor */
    var editor = React.useContext(MonacoEditorContext);
    var _a = __read(useState(null), 2), oneLineEditor = _a[0], setOneLineEditor = _a[1];
    var _b = __read(useState(false), 2), processing = _b[0], setProcessing = _b[1];
    var _c = React.useContext(InputModeContext), commandLineMode = _c.commandLineMode, commandLineInput = _c.commandLineInput, setCommandLineInput = _c.setCommandLineInput;
    var inputRef = useRef();
    // Run the command
    var runCommand = React.useCallback(function () {
        if (processing)
            return;
        var pos = editor.getPosition();
        editor.executeEdits("command-line", [{
                range: monaco.Selection.fromPositions(pos, editor.getModel().getFullModelRange().getEndPosition()),
                text: commandLineInput + "\n",
                forceMoveMarkers: false
            }]);
        editor.setPosition(pos);
        setProcessing(true);
    }, [commandLineInput, editor]);
    useEffect(function () {
        if (oneLineEditor && oneLineEditor.getValue() !== commandLineInput) {
            oneLineEditor.setValue(commandLineInput);
        }
    }, [commandLineInput]);
    // React when answer from the server comes back
    useServerNotificationEffect('textDocument/publishDiagnostics', function (params) {
        if (params.uri == editor.getModel().uri.toString()) {
            setProcessing(false);
            if (!hasErrorsOrWarnings(params.diagnostics)) {
                setCommandLineInput("");
                editor.setPosition(editor.getModel().getFullModelRange().getEndPosition());
            }
        }
    }, []);
    useEffect(function () {
        var myEditor = monaco.editor.create(inputRef.current, {
            value: commandLineInput,
            language: "lean4cmd",
            quickSuggestions: false,
            lightbulb: {
                enabled: true
            },
            unicodeHighlight: {
                ambiguousCharacters: false,
            },
            automaticLayout: true,
            minimap: {
                enabled: false
            },
            lineNumbers: 'off',
            glyphMargin: false,
            folding: false,
            lineDecorationsWidth: 0,
            lineNumbersMinChars: 0,
            'semanticHighlighting.enabled': true,
            overviewRulerLanes: 0,
            hideCursorInOverviewRuler: true,
            scrollbar: {
                vertical: 'hidden',
                horizontalScrollbarSize: 3
            },
            overviewRulerBorder: false,
            theme: 'vs-code-theme-converted',
            contextmenu: false
        });
        setOneLineEditor(myEditor);
        var abbrevRewriter = new AbbreviationRewriter(new AbbreviationProvider(), myEditor.getModel(), myEditor);
        return function () { abbrevRewriter.dispose(); myEditor.dispose(); };
    }, []);
    useEffect(function () {
        if (!oneLineEditor)
            return;
        // Ensure that our one-line editor can only have a single line
        var l = oneLineEditor.getModel().onDidChangeContent(function (e) {
            var value = oneLineEditor.getValue();
            setCommandLineInput(value);
            var newValue = value.replace(/[\n\r]/g, '');
            if (value != newValue) {
                oneLineEditor.setValue(newValue);
            }
        });
        return function () { l.dispose(); };
    }, [oneLineEditor, setCommandLineInput]);
    useEffect(function () {
        if (!oneLineEditor)
            return;
        // Run command when pressing enter
        var l = oneLineEditor.onKeyUp(function (ev) {
            if (ev.code === "Enter") {
                runCommand();
            }
        });
        return function () { l.dispose(); };
    }, [oneLineEditor, runCommand]);
    var handleSubmit = function (ev) {
        ev.preventDefault();
        runCommand();
    };
    return React.createElement("div", { className: "command-line" },
        React.createElement("form", { onSubmit: handleSubmit },
            React.createElement("div", { className: "command-line-input-wrapper" },
                React.createElement("div", { ref: inputRef, className: "command-line-input" })),
            React.createElement("button", { type: "submit", disabled: processing, className: "btn btn-inverted" },
                React.createElement(FontAwesomeIcon, { icon: faWandMagicSparkles }),
                " Execute")));
}
/** Checks whether the diagnostics contain any errors or warnings to check whether the level has
   been completed.*/
function hasErrorsOrWarnings(diags) {
    return diags.some(function (d) {
        return !d.message.startsWith("unsolved goals") &&
            (d.severity == DiagnosticSeverity.Error || d.severity == DiagnosticSeverity.Warning);
    });
}
