/* Mostly copied from https://github.com/leanprover/vscode-lean4/blob/master/lean4-infoview/src/infoview/goals.tsx */
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import * as React from 'react';
import { InteractiveCode } from '../../../../node_modules/lean4-infoview/src/infoview/interactiveCode';
import { InteractiveHypothesisBundle_nonAnonymousNames, TaggedText_stripTags } from '@leanprover/infoview-api';
import { WithTooltipOnHover } from '../../../../node_modules/lean4-infoview/src/infoview/tooltips';
import { EditorContext } from '../../../../node_modules/lean4-infoview/src/infoview/contexts';
import { LocationsContext, SelectableLocation } from '../../../../node_modules/lean4-infoview/src/infoview/goalLocation';
import { Hints } from './hints';
import { CommandLine } from './CommandLine';
import { InputModeContext } from '../Level';
/** Returns true if `h` is inaccessible according to Lean's default name rendering. */
function isInaccessibleName(h) {
    return h.indexOf('✝') >= 0;
}
function goalToString(g) {
    var e_1, _a;
    var ret = '';
    if (g.userName) {
        ret += "case ".concat(g.userName, "\n");
    }
    try {
        for (var _b = __values(g.hyps), _c = _b.next(); !_c.done; _c = _b.next()) {
            var h = _c.value;
            var names = InteractiveHypothesisBundle_nonAnonymousNames(h).join(' ');
            ret += "".concat(names, " : ").concat(TaggedText_stripTags(h.type));
            if (h.val) {
                ret += " := ".concat(TaggedText_stripTags(h.val));
            }
            ret += '\n';
        }
    }
    catch (e_1_1) { e_1 = { error: e_1_1 }; }
    finally {
        try {
            if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
        }
        finally { if (e_1) throw e_1.error; }
    }
    ret += "\u22A2 ".concat(TaggedText_stripTags(g.type));
    return ret;
}
export function goalsToString(goals) {
    return goals.goals.map(goalToString).join('\n\n');
}
function getFilteredHypotheses(hyps, filter) {
    return hyps.reduce(function (acc, h) {
        if (h.isInstance && !filter.showInstance)
            return acc;
        if (h.isType && !filter.showType)
            return acc;
        var names = filter.showHiddenAssumption ? h.names : h.names.filter(function (n) { return !isInaccessibleName(n); });
        var hNew = filter.showLetValue ? __assign(__assign({}, h), { names: names }) : __assign(__assign({}, h), { names: names, val: undefined });
        if (names.length !== 0)
            acc.push(hNew);
        return acc;
    }, []);
}
function Hyp(_a) {
    var h = _a.hyp, mvarId = _a.mvarId;
    var locs = React.useContext(LocationsContext);
    var namecls = 'mr1 ' +
        (h.isInserted ? 'inserted-text ' : '') +
        (h.isRemoved ? 'removed-text ' : '');
    var names = InteractiveHypothesisBundle_nonAnonymousNames(h).map(function (n, i) {
        return React.createElement("span", { className: namecls + (isInaccessibleName(n) ? 'goal-inaccessible ' : ''), key: i },
            React.createElement(SelectableLocation, { locs: locs, loc: mvarId && h.fvarIds && h.fvarIds.length > i ?
                    { mvarId: mvarId, loc: { hyp: h.fvarIds[i] } } :
                    undefined, alwaysHighlight: false }, n));
    });
    var typeLocs = React.useMemo(function () {
        return locs && mvarId && h.fvarIds && h.fvarIds.length > 0 ? __assign(__assign({}, locs), { subexprTemplate: { mvarId: mvarId, loc: { hypType: [h.fvarIds[0], ''] } } }) :
            undefined;
    }, [locs, mvarId, h.fvarIds]);
    var valLocs = React.useMemo(function () {
        return h.val && locs && mvarId && h.fvarIds && h.fvarIds.length > 0 ? __assign(__assign({}, locs), { subexprTemplate: { mvarId: mvarId, loc: { hypValue: [h.fvarIds[0], ''] } } }) :
            undefined;
    }, [h.val, locs, mvarId, h.fvarIds]);
    return React.createElement("div", null,
        React.createElement("strong", { className: "goal-hyp" }, names),
        ":\u00A0",
        React.createElement(LocationsContext.Provider, { value: typeLocs },
            React.createElement(InteractiveCode, { fmt: h.type })),
        h.val &&
            React.createElement(LocationsContext.Provider, { value: valLocs },
                "\u00A0:=\u00A0",
                React.createElement(InteractiveCode, { fmt: h.val })));
}
/**
 * Displays the hypotheses, target type and optional case label of a goal according to the
 * provided `filter`. */
export var Goal = React.memo(function (props) {
    var goal = props.goal, filter = props.filter, showHints = props.showHints, commandLine = props.commandLine;
    var filteredList = getFilteredHypotheses(goal.hyps, filter);
    var hyps = filter.reverse ? filteredList.slice().reverse() : filteredList;
    var locs = React.useContext(LocationsContext);
    var goalLocs = React.useMemo(function () {
        return locs && goal.mvarId ? __assign(__assign({}, locs), { subexprTemplate: { mvarId: goal.mvarId, loc: { target: '' } } }) :
            undefined;
    }, [locs, goal.mvarId]);
    var goalLi = React.createElement("div", { key: 'goal' },
        React.createElement("div", { className: "goal-title" }, "Goal: "),
        React.createElement(LocationsContext.Provider, { value: goalLocs },
            React.createElement(InteractiveCode, { fmt: goal.type })));
    // let cn = 'font-code tl pre-wrap bl bw1 pl1 b--transparent '
    // if (props.goal.isInserted) cn += 'b--inserted '
    // if (props.goal.isRemoved) cn += 'b--removed '
    var hints = React.createElement(Hints, { hints: goal.hints, key: goal.mvarId });
    var objectHyps = hyps.filter(function (hyp) { return !hyp.isAssumption; });
    var assumptionHyps = hyps.filter(function (hyp) { return hyp.isAssumption; });
    var commandLineMode = React.useContext(InputModeContext).commandLineMode;
    return React.createElement("div", null,
        filter.reverse && goalLi,
        !commandLine && objectHyps.length > 0 &&
            React.createElement("div", { className: "hyp-group" },
                React.createElement("div", { className: "hyp-group-title" }, "Objects:"),
                objectHyps.map(function (h, i) { return React.createElement(Hyp, { hyp: h, mvarId: goal.mvarId, key: i }); })),
        !commandLine && assumptionHyps.length > 0 &&
            React.createElement("div", { className: "hyp-group" },
                React.createElement("div", { className: "hyp-group-title" }, "Assumptions:"),
                assumptionHyps.map(function (h, i) { return React.createElement(Hyp, { hyp: h, mvarId: goal.mvarId, key: i }); })),
        commandLine && commandLineMode && React.createElement(CommandLine, null),
        !filter.reverse && goalLi,
        showHints && hints);
});
export var MainAssumptions = React.memo(function (props) {
    var goals = props.goals, filter = props.filter;
    var goal = goals[0];
    var filteredList = getFilteredHypotheses(goal.hyps, filter);
    var hyps = filter.reverse ? filteredList.slice().reverse() : filteredList;
    var locs = React.useContext(LocationsContext);
    var goalLocs = React.useMemo(function () {
        return locs && goal.mvarId ? __assign(__assign({}, locs), { subexprTemplate: { mvarId: goal.mvarId, loc: { target: '' } } }) :
            undefined;
    }, [locs, goal.mvarId]);
    var goalLi = React.createElement("div", { key: 'goal' },
        React.createElement("div", { className: "goal-title" }, "Goal: "),
        React.createElement(LocationsContext.Provider, { value: goalLocs },
            React.createElement(InteractiveCode, { fmt: goal.type })));
    var objectHyps = hyps.filter(function (hyp) { return !hyp.isAssumption; });
    var assumptionHyps = hyps.filter(function (hyp) { return hyp.isAssumption; });
    return React.createElement("div", { id: "main-assumptions" },
        React.createElement("div", { className: "goals-section-title" }, "Current Goal"),
        filter.reverse && goalLi,
        objectHyps.length > 0 &&
            React.createElement("div", { className: "hyp-group" },
                React.createElement("div", { className: "hyp-group-title" }, "Objects:"),
                objectHyps.map(function (h, i) { return React.createElement(Hyp, { hyp: h, mvarId: goal.mvarId, key: i }); })),
        assumptionHyps.length > 0 &&
            React.createElement("div", { className: "hyp-group" },
                React.createElement("div", { className: "hyp-group-title" }, "Assumptions:"),
                assumptionHyps.map(function (h, i) { return React.createElement(Hyp, { hyp: h, mvarId: goal.mvarId, key: i }); })));
});
export var OtherGoals = React.memo(function (props) {
    var goals = props.goals, filter = props.filter;
    return React.createElement(React.Fragment, null, goals && goals.length > 1 &&
        React.createElement("div", { id: "other-goals", className: "other-goals" },
            React.createElement("div", { className: "goals-section-title" }, "Further Goals"),
            goals.slice(1).map(function (goal, i) {
                return React.createElement("details", { key: i },
                    React.createElement("summary", null,
                        React.createElement(InteractiveCode, { fmt: goal.type })),
                    React.createElement(Goal, { commandLine: false, filter: filter, goal: goal }));
            })));
});
export var ProofDisplay = React.memo(function (props) {
    var proof = props.proof;
    var steps = proof.match(/.+/g);
    return React.createElement(React.Fragment, null, steps &&
        React.createElement("div", { id: "current-proof" },
            React.createElement("div", { className: "goals-section-title" }, "Proof history"),
            React.createElement("div", { className: "proof-display-wrapper" },
                React.createElement("div", { className: "proof-display" }, steps.map(function (s) {
                    return React.createElement("div", null, s);
                })))));
});
export function Goals(_a) {
    var goals = _a.goals, filter = _a.filter;
    if (goals.goals.length === 0) {
        return React.createElement(React.Fragment, null, "No goals");
    }
    else {
        return React.createElement(React.Fragment, null, goals.goals.map(function (g, i) { return React.createElement(Goal, { commandLine: false, key: i, goal: g, filter: filter }); }));
    }
}
/**
 * Display goals together with a header containing the provided children as well as buttons
 * to control how the goals are displayed.
 */
export var FilteredGoals = React.memo(function (_a) {
    var headerChildren = _a.headerChildren, goals = _a.goals;
    var ec = React.useContext(EditorContext);
    var copyToCommentButton = React.createElement("a", { className: "link pointer mh2 dim codicon codicon-quote", "data-id": "copy-goal-to-comment", onClick: function (e) {
            e.preventDefault();
            if (goals)
                void ec.copyToComment(goalsToString(goals));
        }, title: "copy state to comment" });
    var _b = __read(React.useState({ reverse: false, showType: true, showInstance: true, showHiddenAssumption: true, showLetValue: true }), 2), goalFilters = _b[0], setGoalFilters = _b[1];
    var sortClasses = 'link pointer mh2 dim codicon ' + (goalFilters.reverse ? 'codicon-arrow-up ' : 'codicon-arrow-down ');
    var sortButton = React.createElement("a", { className: sortClasses, title: "reverse list", onClick: function (_) { return setGoalFilters(function (s) { return (__assign(__assign({}, s), { reverse: !s.reverse })); }); } });
    var mkFilterButton = function (filterFn, filledFn, name) {
        return React.createElement("a", { className: 'link pointer tooltip-menu-content', onClick: function (_) { setGoalFilters(filterFn); } },
            React.createElement("span", { className: 'tooltip-menu-icon codicon ' + (filledFn(goalFilters) ? 'codicon-check ' : 'codicon-blank ') }, "\u00A0"),
            React.createElement("span", { className: 'tooltip-menu-text ' }, name));
    };
    var filterMenu = React.createElement("span", null,
        mkFilterButton(function (s) { return (__assign(__assign({}, s), { showType: !s.showType })); }, function (gf) { return gf.showType; }, 'types'),
        React.createElement("br", null),
        mkFilterButton(function (s) { return (__assign(__assign({}, s), { showInstance: !s.showInstance })); }, function (gf) { return gf.showInstance; }, 'instances'),
        React.createElement("br", null),
        mkFilterButton(function (s) { return (__assign(__assign({}, s), { showHiddenAssumption: !s.showHiddenAssumption })); }, function (gf) { return gf.showHiddenAssumption; }, 'hidden assumptions'),
        React.createElement("br", null),
        mkFilterButton(function (s) { return (__assign(__assign({}, s), { showLetValue: !s.showLetValue })); }, function (gf) { return gf.showLetValue; }, 'let-values'));
    var isFiltered = !goalFilters.showInstance || !goalFilters.showType || !goalFilters.showHiddenAssumption || !goalFilters.showLetValue;
    var filterButton = React.createElement(WithTooltipOnHover, { mkTooltipContent: function () { return filterMenu; } },
        React.createElement("a", { className: 'link pointer mh2 dim codicon ' + (isFiltered ? 'codicon-filter-filled ' : 'codicon-filter ') }));
    return React.createElement("div", { style: { display: goals !== undefined ? 'block' : 'none' } },
        React.createElement("details", { open: true },
            React.createElement("summary", { className: 'mv2 pointer' },
                headerChildren,
                React.createElement("span", { className: 'fr' },
                    copyToCommentButton,
                    sortButton,
                    filterButton)),
            React.createElement("div", { className: 'ml1' }, goals && React.createElement(Goals, { goals: goals, filter: goalFilters }))));
});
