var _a;
import { configureStore } from '@reduxjs/toolkit';
import { connection } from '../connection';
import { apiSlice } from './api';
import { progressSlice } from './progress';
import { saveState } from "./localStorage";
import { debounce } from "debounce";
export var store = configureStore({
    reducer: (_a = {},
        _a[apiSlice.reducerPath] = apiSlice.reducer,
        _a[progressSlice.name] = progressSlice.reducer,
        _a),
    // Make connection available in thunks:
    middleware: function (getDefaultMiddleware) {
        return getDefaultMiddleware({
            thunk: {
                extraArgument: { connection: connection }
            }
        }).concat(apiSlice.middleware);
    },
});
// Save progress in local storage
store.subscribe(
// we use debounce to save the state once each 800ms
// for better performances in case multiple changes occur in a short time
debounce(function () {
    saveState(store.getState()[progressSlice.name]);
}, 800));
