import * as React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import { ConnectionContext, connection } from './connection';
import { store } from './state/store';
import { Provider } from 'react-redux';
import { createHashRouter, RouterProvider, } from "react-router-dom";
import ErrorPage from './ErrorPage';
import Welcome from './components/Welcome';
import Level from './components/Level';
import { monacoSetup } from 'lean4web/client/src/monacoSetup';
import { redirect } from 'react-router-dom';
monacoSetup();
var router = createHashRouter([
    {
        path: "/",
        loader: function () { return redirect("/g/add/addgame"); }
    },
    {
        path: "/g/:owner/:repo",
        element: React.createElement(App, null),
        errorElement: React.createElement(ErrorPage, null),
        children: [
            {
                path: "/g/:owner/:repo",
                element: React.createElement(Welcome, null),
            },
            {
                path: "/g/:owner/:repo/world/:worldId/level/:levelId",
                element: React.createElement(Level, null),
            },
        ],
    },
]);
var container = document.getElementById('root');
var root = createRoot(container);
root.render(React.createElement(React.StrictMode, null,
    React.createElement(Provider, { store: store },
        React.createElement(ConnectionContext.Provider, { value: connection },
            React.createElement(RouterProvider, { router: router })))));
